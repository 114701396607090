import {Guid} from 'guid-typescript';
import axios, {AxiosResponse} from 'axios';
import {Mutex} from 'async-mutex';
import Repository from '@/assets/service/Repository';
import store from '@/store';
import KeepAliveResponse from '@/assets/service/responseTypes/logon/keepAliveResponse/KeepAliveResponse';
import {getSid, setSid, unsetSid} from '@/assets/utils/SessionUtils';
import {getIp} from '@/assets/utils/GuidUtil';
import User from '@/assets/models/User';

import TblUserResponse from "@/assets/service/responseTypes/logon/tblUserResponse/TblUserResponse";
import LogonResponse from "@/assets/service/responseTypes/logon/logonResponse/LogonResponse";
import ServiceErrors from "@/assets/service/responseTypes/ServiceErrors";
import GetLicenceInformationResponse from "@/assets/service/responseTypes/about/GetLicenseInformationResponse";
import GetLicenceInformationResponseEntry
    from "@/assets/service/responseTypes/about/GetLicenseInformationResponseEntry";

import Config from "@/assets/service/responseTypes/logon/configurationRespone/Config";
import Branch from "@/assets/models/Branch";
import Card from "@/assets/models/Card";
import Transaction from "@/assets/models/Transaction";
import BarCodeResult from "@/assets/models/BarCodeResult";
import {BarCodeFormat} from "@/assets/models/BarCodeFormat";
import TseObject, {PROCESSDATA, ZAHLUNG} from "@/assets/models/TseObject";
import {int} from "@zxing/library/es2015/customTypings";

export default class Webservice {
    public static AppId: string = 'APRO.KASSENBUCH';

    // General Endpoints
    private static readonly validateUserEndpoint: string = '/validateuser';
    private static readonly loginEndpoint: string = '/logon';
    private static readonly logoutEndpoint: string = '/logoff';
    private static readonly fetchUserEndpoint: string = '/Tbl_Benutzer';
    private static readonly keepAliveEndpoint: string = '/KeepAlive';
    private static readonly getUserPermissionsEndpoint: string = '/get_Kassenbuch_Benutzerrechte';
    private static readonly getLicenceInformationEndpoint: string = '/getLicenceInformation';
    private static readonly cashbookEnumerationsEndpoint: string = '/Lst_KassenbuchEnumerations';

    // Cashbook Endpoints
    private static readonly listCashbookEndpoint: string = '/Lst_Kassenbuch';

    // Mutex to Cancel Requests
    private static getPermissionMutex: Mutex = new Mutex();

    // eslint-disable-next-line class-methods-use-this
    public static setService(service: string) {
        Repository.defaults.baseURL = service;
    }

    // eslint-disable-next-line class-methods-use-this
    public static getService(): string | undefined {
        return Repository.defaults.baseURL;
    }

    public static async validatePin(pin: string): Promise<boolean> {
        return true;
    }

    public static async getConfig(appId: string): Promise<any> {
        let returnValue = {
            SCANNER: {
                AUTHAUFLADEN: 1,
                PINSICHERUNG: 0
            },
            ADMIN: null
        }
        try {
            const response = await axios.post(Repository.defaults.baseURL + `/Common/get_App_Config`, {AppID: appId});
            if (response.data.get_App_Config_response?.ServiceErrors) {
                throw new Error(response.data.get_App_Config_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            returnValue = response.data?.get_App_Config_response?.Configuration[appId + ".CONF"];
        } catch (e) {
            console.error(e);
        } finally {
            return returnValue;
        }
    }

    //Kundenkarten_PunkteEinloesen
    public static async checkoutPoints(amount: number, branchId: number, cardId: string, tse: string, isMoney: boolean = true): Promise<{ PunkteEingeloest: number, Transaktionsnummer: number }> {

        const sid = getSid();
        if (sid) {
            return await Repository.post("/Kundenkarten_PunkteEinloesen",
                {
                    SID: sid,
                    Filiale: branchId,
                    Betrag: isMoney ? amount : 0,
                    Punkte: !isMoney ? amount : 0,
                    KundenkarteID: cardId,
                    Buchungstext: tse
                }).then((result) => {
                this.getErrors(result.data.Kundenkarten_PunkteEinloesen_response);
                return result.data.Kundenkarten_PunkteEinloesen_response;
            });
        } else {
            return Promise.reject();
        }
    }

    //ParseTSEQRCode
    public static async getTSE(barCode: BarCodeResult, cardId: string): Promise<TseObject> {
        // console.log('get tse');
        // if(barCode.format) {
        //console.log(barCode);
        if (barCode.format === BarCodeFormat.QR_CODE || !barCode.format) {
            return this.parseTSEQrCode(barCode.text);
        }
        if (barCode.format === BarCodeFormat.EAN_13 || barCode.format === BarCodeFormat.EAN_8 || barCode.format === BarCodeFormat.CODE_128) {
            return this.parseTSEEANCode(barCode, cardId);
        }
        // }else {
        //     barCode.text.substr(2, barCode.text.length -2)
        //     let tse = await this.parseTSEQrCode(barCode.text.substr(2, barCode.text.length -2));
        //     console.log('tse found');
        //     console.log(tse);
        //     if(!tse) {
        //         barCode.format = BarCodeFormat.EAN_8;
        //         tse = await this.parseTSEEANCode(barCode, cardId);
        //         if(!tse) {
        //             barCode.format = BarCodeFormat.CODE_128;
        //             tse = await this.parseTSEEANCode(barCode, cardId);
        //         }
        //
        //         return tse;
        //     }
        //     return Promise.reject();
        // }
        return Promise.reject();
    }

    //ParseTSEQRCode
    public static async parseTSEQrCode(qrCode: string): Promise<TseObject> {
        const sid = getSid();
        if (sid) {
            return Repository.post("/ParseTSEQRCode ",
                {SID: sid, QR: qrCode}).then((response) => {
                return <TseObject>response?.data?.ParseTSEQRCode_response?.Kassenbeleg;
            });
        } else {
            return Promise.reject();
        }
    }

    //ParseTSEQRCode
    public static async parseTSEEANCode(barCode: BarCodeResult, cardId: string): Promise<TseObject> {
        const sid = getSid();
        if (sid) {
            return Repository.post("/ParseBarcode",
                {
                    SID: sid,
                    KundenkarteID: cardId,
                    Type: BarCodeFormat[barCode.format].replace("_", ""),
                    EAN: barCode.text
                }).then(response => {
                this.getErrors(response.data.ParseBarcode_response);
                if (response.data.ParseBarcode_response.Zahlbetrag > 0) {
                    const tseObject = new TseObject();
                    tseObject.PROCESSDATA = new PROCESSDATA();
                    tseObject.PROCESSDATA.ZAHLUNG_1 = new ZAHLUNG();
                    tseObject.PROCESSDATA.ZAHLUNG_1.BETRAG = response.data.ParseBarcode_response.Zahlbetrag;
                    return tseObject;
                }
                return Promise.reject();
            });
        } else {
            return Promise.reject();
        }
    }

    //Kundenkarten_UmsatzBuchen
    public static async checkout(amount: number, branchId: number, cardId: string, tse: string): Promise<number> {
        const sid = getSid();
        if (sid) {
            return await Repository.post("/Kundenkarten_UmsatzBuchen",
                {
                    SID: sid,
                    Filiale: branchId,
                    Guthaben: amount,
                    KundenkarteID: cardId,
                    Buchungstext: tse
                }).then((result) => {
                this.getErrors(result.data.Kundenkarten_UmsatzBuchen_response);
                return result.data.Kundenkarten_UmsatzBuchen_response.Transaktionsnummer;
            });
        } else {
            return Promise.reject();
        }
    }

    //Kundenkarten_GuthabenBuchen
    public static async changeCredit(amount: number, branchId: number, cardId: string, bookingText: string = ''): Promise<number> {
        const sid = getSid();
        if (sid) {
            return await Repository.post("/Kundenkarten_GuthabenBuchen",
                {
                    SID: sid,
                    Filiale: branchId,
                    Guthaben: amount,
                    KundenkarteID: cardId,
                    Buchungstext: bookingText
                }).then((result) => {
                this.getErrors(result.data.Kundenkarten_GuthabenBuchen_response);
                return result.data.Kundenkarten_GuthabenBuchen_response.Transaktionsnummer;
            });
        } else {
            return Promise.reject();
        }
    }

    //Kundenkarten_BuchungStornieren
    public static async cancelBooking(cardId: string, transactionId: number, branchId: string, cancellationText: string = ''): Promise<any> {
        const sid = getSid();
        if (sid) {
            await Repository.post("/Kundenkarten_BuchungStornieren",
                {
                    SID: sid,
                    KundenkarteID: cardId,
                    Filiale: branchId,
                    Transaktionsnummer: transactionId,
                    Stornotext: cancellationText
                }).then((result) => {
                this.getErrors(result.data.Kundenkarten_BuchungStornieren_response);
            });
        } else {
            return Promise.reject();
        }
    }

    //get_KundenkartenTransaktion
    public static async getTransaction(cardId: string, transactionNumber: number | undefined = undefined): Promise<Transaction> {
        const sid = getSid();
        if (sid) {
            const request = {SID: sid, KundenkarteID: cardId, Transaktionsnummer: transactionNumber};
            return await Repository.post("/get_KundenkartenTransaktion",
                request).then((result) => {
                this.getErrors(result.data.get_KundenkartenTransaktion_response);
                return result.data.get_KundenkartenTransaktion_response.Transaktion;
            });
        } else {
            return Promise.reject();
        }
    }

    public static async cancelTransactions(cardId: string, branchId: string, transactions: number[]): Promise<void> {
        const sid = getSid();
        if (sid) {
            for (let i = 0; i < transactions.length; i++) {
                await this.cancelTransaction(cardId, branchId, transactions[i]);
            }
        } else {
            return Promise.reject();
        }
    }

    public static async cancelTransaction(cardId: string, branchId: string, transactionNumber: number | undefined = undefined): Promise<void> {
        const sid = getSid();
        if (sid) {
            if (transactionNumber) {
                return await this.cancelBooking(cardId, transactionNumber, branchId);
            } else {
                const transaction = await this.getTransaction(cardId);
                if (transaction) {
                    return await this.cancelBooking(cardId, transaction.NUMMER, branchId);
                } else {
                    return Promise.reject();
                }
            }
        } else {
            return Promise.reject();
        }
    }

    //get_Kundenkarte
    // @ts-ignore
    public static async getCard(cardId: string): Promise<Card> {
        const sid = getSid();
        if (sid) {
            return Repository.post("/get_Kundenkarte",
                {SID: sid, KundenkarteID: cardId}).then(result => {
                this.getErrors(result.data.get_Kundenkarte_response);
                const card = result?.data?.get_Kundenkarte_response?.Kundenkarte;
                card.AKTIONEN = result?.data?.get_Kundenkarte_response?.Aktionen;

                // card.KONFIGURATION.PINSICHERUNG = true;
                // card.KONFIGURATION.PIN= "1234";
                return card;
            });
        } else {
            return Promise.reject();
        }
    }

    // @ts-ignore
    public static async getCardManual(cardId: string): Promise<Card> {
        const sid = getSid();
        if (sid) {
            let scannerType = "";
            // const dc = JSON.parse(await store.getters.deviceConfig);
            // if(codeType === 0 ) {
            //     scannerType = "2D";
            // }else if(codeType === 1) {
            //     scannerType = "1D";
            // }else {
            //     scannerType = "Handeingabe";
            // }
            return Repository.post("/get_Kundenkarte",
                {SID: sid, KundenkarteID: cardId, ScannerTyp: "Handeingabe"}).then(result => {
                this.getErrors(result.data.get_Kundenkarte_response);
                const card = result?.data?.get_Kundenkarte_response?.Kundenkarte;
                card.AKTIONEN = result?.data?.get_Kundenkarte_response?.Aktionen;

                // card.KONFIGURATION.PINSICHERUNG = true;
                // card.KONFIGURATION.PIN= "1234";
                return card;
            });
        } else {
            return Promise.reject();
        }
    }

    public static async getCardBarcode(cardId: string): Promise<Card> {
        const sid = getSid();
        if (sid) {
            return Repository.post("/get_Kundenkarte",
                {SID: sid, KundenkarteID: cardId, ScannerTyp: "Handeingabe"}).then(result => {
                this.getErrors(result.data.get_Kundenkarte_response);
                const card = result?.data?.get_Kundenkarte_response?.Kundenkarte;
                card.AKTIONEN = result?.data?.get_Kundenkarte_response?.Aktionen;

                // card.KONFIGURATION.PINSICHERUNG = true;
                // card.KONFIGURATION.PIN= "1234";
                return card;
            });
        } else {
            return Promise.reject();
        }
    }

    private static getErrors(response: any): void {
        if (response && response.ServiceErrors) {
            const serviceErrors = response.ServiceErrors;
            const error = serviceErrors.filter(value => value.ERRORRECORDID.length === 0);
            if (serviceErrors.filter(value => value.ERRORDESCRIPTION.includes('Anmeldung erforderlich')).length > 0) {
                Webservice.logout();
            } else {
                throw {
                    message: serviceErrors[0].ERRORDESCRIPTION
                };
            }
        }
    }

    /*****************************************************************
     ************************ General Requests ***********************
     *****************************************************************/

    public static keepAlive(callbackSuccess: () => void, callbackError: () => void): void {
        if (getSid()) {
            Repository.post(Webservice.keepAliveEndpoint,
                {SID: getSid()}).then((value: AxiosResponse<KeepAliveResponse>) => {
                if (!value.data.KeepAlive_response) {
                    callbackSuccess();
                    setSid(getSid());
                } else {
                    // eslint-disable-next-line no-restricted-globals
                    callbackError();

                }
            }).catch((reason) => {
                this.handleRealErrors(reason);
                callbackError();
            });
        } else {
            // eslint-disable-next-line no-restricted-globals
            callbackError();
        }
    }

    public static loadBranches(): Promise<any> {
        return Repository.post("/Tbl_Filialen").then((value) => {
            return value;
        });
    }

    public static async sessionInfo(sid: string): Promise<any> {
        const response = await Repository.post("/getSessionInformation",
            {
                SID: sid
            });

        if (response && response.data && response.data.getSessionInformation_response) {
            if (response.data.getSessionInformation_response.ServiceErrors) {
                throw new Error(response.data.getSessionInformation_response.ServiceErrors[0].ERRORDESCRIPTION);
            }else {

                store.commit('SET_GUID', {guid: response.data.getSessionInformation_response.MachineID});
                store.commit('SET_ACTIVE_USERID', {activeUserId: response.data.getSessionInformation_response.User.ID});

                setSid(sid);
                return response.data.getSessionInformation_response;
            }

            // eslint-disable-next-line no-param-reassign

        } else {
            return undefined
        }
    }

    public static loadUserAndConfig(branch: Branch | undefined, callbackFinally: () => void,
                                    callbackSuccess: (response: User[]) => void,
                                    callbackFail: () => void): void {
        let config = new Promise(resolve => {
            let newConfig = new Config();
            newConfig.idCard = true;
            newConfig.maxTopUp = 100;
            return newConfig;
        });


        Repository.post<TblUserResponse>(Webservice.fetchUserEndpoint, {Filiale: branch?.Nummer}).then((value) => {

            if (value) {
                store.commit('SET_CONFIGURATION', value);
            }
            if (value.data.Tbl_Benutzer_response.ServiceErrors === undefined) {
                callbackSuccess(value.data.Tbl_Benutzer_response.Tbl_Benutzer);
            } else {
                this.preAnalyzeErrors(value.data.Tbl_Benutzer_response.ServiceErrors);
                callbackFail();
            }
        })
            .catch((reason) => {
                this.handleRealErrors(reason);
                callbackFail();
            })
            .finally(() => {
                callbackFinally();
            });

    }

    public static async validateUser(user: number,
                                     password: string): Promise<boolean> {
        const sid = getSid();
        const response = await Repository.post(Webservice.validateUserEndpoint,
            {
                SID: sid,
                UserID: String(user),
                UserPass: password,
            });
        if (response && response.data && response.data.ValidateUser_response) {
            this.getErrors(response.data.ValidateUser_response);

            return response.data.ValidateUser_response.Valid;
        }

        return false;
    }

    public static injectLogin(userId: string, sessionId: string): void {
        setSid(sessionId);
        store.commit('SET_ACTIVE_USERID', {activeUserId: userId});
    }

    public static login(user: number,
                        password: string,
                        callbackFinally: () => void,
                        callbackSuccess: () => void,
                        callbackFail: (serviceErrors: ServiceErrors[]) => void): void {

        let guid = store.getters.guid;
            if (!guid) {
                // eslint-disable-next-line no-param-reassign
                guid = Guid.create().toString();
                store.commit('SET_GUID', {guid});
            }
            Repository.post<LogonResponse>(Webservice.loginEndpoint,
                {
                    AppID: Webservice.AppId,
                    MachineId: guid,
                    UserID: String(user),
                    UserPass: password,
                })
                .then((value) => {
                    if (value.data.logon_response.ServiceErrors === undefined
                        && value.data.logon_response.Success) {
                        if (value.data.logon_response.SID) {
                            setSid(value.data.logon_response.SID);
                        }
                        store.commit('SET_ACTIVE_USERID', {activeUserId: user});
                        // const errTest:ServiceErrors[]= [{
                        //     ERRORNUMBER: 100,
                        //     ERRORPOSITION: "333",
                        //     ERRORRECORDID: "22235",
                        //     ERRORDESCRIPTION: "Bla Bla",
                        //     ERRORRECOMMENDATION: "Bla Bla RECOMMENDATION"
                        // }];
                        // callbackFail(errTest);
                        callbackSuccess();
                    } else if (value.data.logon_response.ServiceErrors && value.data.logon_response.ServiceErrors.length > 0) {
                        this.preAnalyzeErrors(value.data.logon_response.ServiceErrors);

                        callbackFail(value.data.logon_response.ServiceErrors);
                    }
                })
                .catch((reason) => {
                    this.handleRealErrors(reason);
                })
                .finally(() => {
                    callbackFinally();
                });

    }

    public static loginWithCard(result,
                                callbackFinally: () => void,
                                callbackSuccess: () => void,
                                callbackFail: (serviceErrors: ServiceErrors[]) => void): void {
        this.login(5, '',
            callbackFinally,
            callbackSuccess,
            callbackFail);
    }

    public static getLicenseInformation(callbackSuccess: (response: GetLicenceInformationResponseEntry) => void) {
        Repository.post<GetLicenceInformationResponse>(Webservice.getLicenceInformationEndpoint).then((value) => {
            callbackSuccess(value.data.getLicenceInformation_response);
        }).catch((exception) => {
            callbackSuccess(new GetLicenceInformationResponseEntry());
        });
    }


    public static logout() {
        Repository.post(Webservice.logoutEndpoint,
            {
                SID: getSid(),
            }).then(() => {
            unsetSid();
            location.reload();
        });
    }


    /*****************************************************************
     ************************* Customer Card *************************
     *****************************************************************/

    public static getCustomerCard(cardnumber: string, callbackSuccess: (balance: number) => void): void {
        callbackSuccess((Math.round(100 * (Math.random() >= 0.5 ? (Math.random() * 100) : 10))) / 100);
    }

    /*****************************************************************
     *********************** Bill Transactions ***********************
     *****************************************************************/

    // public static getTransaction(billCode: string, callbackSuccess: (result) => void) {
    //     callbackSuccess({amount: (Math.round(100 * (Math.random() * 25))) / 100});
    // }

    public static redeem(callbackSuccess: () => void) {
        callbackSuccess();
    }

    public static topUp(callbackSuccess: () => void) {
        callbackSuccess();
    }

    /*****************************************************************
     *********************** Cashbook Requests ***********************
     *****************************************************************/


    /*****************************************************************
     ************************* Error Handling ************************
     *****************************************************************/

    public static preAnalyzeErrors(serviceErrors: ServiceErrors[]) {
        if (serviceErrors.filter(value => value.ERRORDESCRIPTION.includes('Anmeldung erforderlich')).length > 0) {
            // location.reload();
        } else if (serviceErrors.filter(value => value.ERRORRECORDID.length === 0).length) {
            store.commit('SET_ERROR', serviceErrors.filter(value => value.ERRORRECORDID.length === 0)[0]);
        }
    }

    private static handleRealErrors(reason: { isAxiosError: any; response: undefined; }) {
        if (reason.isAxiosError && reason.response === undefined) {
            //store.commit('SET_ERROR', ErrorConstants.NO_CONNECTION);
        }
    }
}
